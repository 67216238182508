var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "mb-1", class: _vm.width_limit ? "short" : "regular" },
    [
      _c(
        "CCardBody",
        {
          class: ["d-flex align-items-center", _vm.padding === false && "p-0"]
        },
        [
          _c(
            "div",
            {
              class: [
                "c-widget-icon-container",
                "me-3",
                "mr-2",
                "bg-" + _vm.color,
                _vm.invert ? "fg-primary" : "text-white",
                _vm.padding ? "p-3" : "p-4"
              ],
              staticStyle: { "border-radius": "0.25rem" }
            },
            [_vm._t("default"), _vm._t("icon")],
            2
          ),
          _c("div", [_vm._t("value"), _vm._t("title")], 2)
        ]
      ),
      _vm.footer ? _c("CCardFooter", [_vm._t("footer")], 2) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }