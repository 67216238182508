<template>
  <CCard class='mb-1' :class="width_limit? 'short': 'regular'">
    <CCardBody :class="['d-flex align-items-center', padding === false && 'p-0']">
      <div
        :class="[
         'c-widget-icon-container',
          'me-3',
          'mr-2',
          `bg-${color}`,
          invert? 'fg-primary': 'text-white',
          padding ? 'p-3' : 'p-4',
        ]"
        style="border-radius:0.25rem; "
      >
        <slot />
        <slot name="icon" />
      </div>

      <div>
        <slot name="value" />
        <slot name="title" />
      </div>
    </CCardBody>
    <CCardFooter v-if="footer">
      <slot name="footer" />
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  name: 'CWidgetStatsF',
  props: {
    /**
     * Sets the color context of the component to one of CoreUI’s themed colors. [docs]
     *
     * @values 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string
     */
    color: String,
    /**
     * Set padding of your component. [docs]
     */
    padding: {
      type: Boolean,
      default: true,
    },
    /**
     * Title node for your component. If you want to pass non-string value please use dedicated slot `<template #title>...</template>`
     */
    title: {
      type: String,
      default: undefined,
      require: false,
    },
    /**
     * Helper text for your component. If you want to pass non-string value please use dedicated slot `<template #text>...</template>`
     */
    text: {
      type: String,
      default: undefined,
      require: false,
    },
    /**
     * Value node for your component. If you want to pass non-string value please use dedicated slot `<template #value>...</template>`
     */
    value: {
      type: [Number, String],
      default: 0,
      require: false,
    },

    footer: {
      type: Boolean,
      default: false,
    },
    width_limit: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style>
.c-widget-icon-container{
  border-radius:0.25rem; /**KH: Should make this CSS Class global & sharable / re-usable  */
}
.card.short {
  max-width: 250px; /**KH: Increased this for balance  */
  min-width: 150px;
}
</style>